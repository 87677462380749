
import { defineComponent, ref } from 'vue'
import { IComObj } from '@/global/types'

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const imgs = [
      'part2-center1.png',
      'part2-center2.png',
      'part2-center3.png',
      'part2-center4.png',
      'part2-center5.png',
      'part2-center6.png',
      'part2-center7.png',
      'part2-center8.png',
      'part2-center9.png',
    ]

    const info = props.data[0].info
    const innerData = ref<IComObj[]>([
      {
        name: '全国一体化政务服务平台标准-全国一体化政务服务平台<br/>电子证照  行政执法证',
        url: 'C0359+全国一体化政务服务平台+电子证照+行政执法证.pdf'
      },
      {
        name: '司法行业标准-行政执法综合管理监督信息系统<br/>数据元和代码集',
        url: 'https://www.moj.gov.cn/pub/sfbgw/zwfw/zwfwbgxz/202401/W020240110412875641643.pdf'
      },
      { name: '司法行业标准-司法行政信息资源中心建设规范', 
        url: 'https://www.moj.gov.cn/pub/sfbgw/zwxxgk/fdzdgknr/fdzdgknrlzyj/lzyjsfhybzj/202109/W020210907589061760824.pdf' 
      },
      { 
        name: '北京市地方标准-行政处罚数据规范', 
        url: 'https://dbba.sacinfo.org.cn/stdDetail/65a4bb927c44ca2256947fc530aca385' 
      },
      { 
        name: '北京市地方标准-行政检查数据规范', 
        url: 'https://dbba.sacinfo.org.cn/stdDetail/367d9207634b5fdda4ced9da05cbb6a6c9dd070e4233e5d3a3ba126dad340b73' 
      },
      { 
        name: '北京市地方标准-行政强制数据规范', 
        url: 'https://dbba.sacinfo.org.cn/stdDetail/367d9207634b5fdda4ced9da05cbb6a652ac7ce5a6bc23c07ab946ed6d6fd0c3' 
      },
      { 
        name: '广东省地方标准-行政执法综合信息数据元规范', 
        url: 'https://dbba.sacinfo.org.cn/stdDetail/f5981c6e04f1d8145c8e972151b59ed2b88d2b73fa64ac73e037cb020faf36f8' 
      },
      { 
        name: '深圳市地方标准-行政执法监督数据规范<br/>第1部分：数据元', 
        url: 'https://dbba.sacinfo.org.cn/stdDetail/609f6f5949cf145d67a6d65d731322d7bae793dcf7aa425aace17693aed0f041' 
      },
      { 
        name: '深圳市地方标准-行政执法监督数据规范<br/>第2部分：电子案卷', 
        url: 'https://dbba.sacinfo.org.cn/stdDetail/609f6f5949cf145d67a6d65d731322d762daef2b553b42ba86a247e74836464d' 
      },
      {},
      {},
      {},
    ])
    imgs.forEach((item: string, index: number) => {
      if(innerData.value[index].name) innerData.value[index].img = require('@/assets/images/WSupervision/' + item)
    })
    return {
      info,
      modules: [Autoplay, Pagination],
      innerData
    }
  }
})
